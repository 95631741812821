@import "framework";

// Vars

$body-size: 16px;
$ref-width: 1366px;
$body-color: #000000;
$background-color: #ffffff;
$body-font-family: 'FranklinITCPro-Light', sans-serif;
$bold-font-family: 'FranklinITCPro-Bold', sans-serif;
$mobile: (body-size: 18px, ref-width: 750px, breakpoint: 750px);

// Global

body {
  background-color: $background-color;
  color: $body-color;
  font-family: $body-font-family;
  text-transform: uppercase;
  @include set-body-size;
  letter-spacing: 0.02em;
  @include mobile {
    @include set-body-size;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
  &:hover {
    //border-bottom: 1px solid $body-color;
    text-decoration: underline;
  }
}

strong, b {
  font-family: $bold-font-family;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: normal;
}

.hoverswitch {
  .on { display: none; }
  &:hover {
    .off { display: none; }
    .on { display: inline-block; }
  }
}

.page {
  width: 100%;
  height: 100%; height: 100vh;
}

.video-wrap-16-9, .video-wrap- {
  @include fixed-proportions-wrap;
}
.video-wrap-3-4 {
  @include fixed-proportions-wrap(300,400);
}

.pattern {
  padding-left: em(19px);
  padding-right: em(19px);
  @include mobile {
    padding-left: em(21px);
    padding-right: em(21px);
  }
  .grid-block {
    @include inline-block;
    vertical-align: top;
    padding-left: em(20px);
    padding-right: em(20px);
    margin-bottom: em(250px);
    @include mobile {
      padding-left: em(21px);
      padding-right: em(21px);
      margin-bottom: em(200px);
    }
  }
}

.homepage-pattern {
  .width-40 { width: 40%; }
  .width-50 { width: 50%; }
  .width-60 { width: 60%; }
  .width-100 { width: 100%; }
}

.artist-page-pattern {
  .grid-block:nth-of-type(6n + 1) { width: 25%; }
  .grid-block:nth-of-type(6n + 2) { width: 37.5%; }
  .grid-block:nth-of-type(6n + 3) { width: 37.5%; }
  .grid-block:nth-of-type(6n + 4) { width: 37.5%; }
  .grid-block:nth-of-type(6n + 5) { width: 37.5%; }
  .grid-block:nth-of-type(6n + 6) { width: 25%; }
  @include mobile {
    .grid-block:nth-of-type(6n + 1) { width: 50%; }
    .grid-block:nth-of-type(6n + 2) { width: 50%; }
    .grid-block:nth-of-type(6n + 3) { width: 40%; }
    .grid-block:nth-of-type(6n + 4) { width: 60%; }
    .grid-block:nth-of-type(6n + 5) { width: 50%; }
    .grid-block:nth-of-type(6n + 6) { width: 50%; }
  }
}

// Layout

body > header {
  padding: em(39px) em(39px) em(23px) em(39px);
  // background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1000;
	@include transition(0.5s);
	&.compact {
		// @include transform(translate(0%,-100%));
	}
  @include mobile {
    padding: em(42px) em(42px) em(29px) em(42px);
  }
  .logo {
    font-size: em(32px);
    @extend b;
    text-align: center;
    position: relative; 
    top: em(-3px);
    @include transition(0.5s);
    @include mobile {
      font-size: em(32px);
    }
    &.centered {
      // top: 42vh;
      top: 35vh;
      @include mobile {
        top: em(-3px);
      }
      .when-sticky { 
        display: none; 
        @include mobile {
          display: block;
        }
      }
      .when-centered { 
        display: block; 
        @include mobile {
          display: none;
        }
      }
      a.swan { 
        font-size: 4.2em; 
        @include mobile {
          font-size: 1em;
        }
      }
    }
    .when-sticky { display: block; }
    .when-centered { display: none; }
    a:hover { text-decoration: none; }
    a.swan { @include transition(0.5s); }
    .hoverswitch {
      .on {
        .open-menu { cursor: pointer; }
        li {
          @include inline-block;
          padding-left: em(30px);
          padding-right: em(30px);
        }
      }
    }
  }
  .burger {
    position: absolute; left: em(39px); top: em(39px);
    width: em(20px);
    cursor: pointer;
    @include mobile {
      width: em(30px);
    }
  }
}

.menu-tray {
  // To remove the gap on the tray, remove the 2 height using "calc"
  // and the "@include mobile" which forces the height.
  position: fixed; left: 0; top: 0;
  width: 100%;
  height: 100%;
  // height: calc(100% - #{em(39px)}); // *
  height: 100vh;
  // height: calc(100vh - #{em(39px)}); // *
  overflow: auto;
  z-index: 2000;
  background-color: #ffeae0;
  @include vendor(animation, bgloop 20s infinite);
  @include transform(translate(0%,-100%));
  @include transition(0.5s);
  @include mobile {
    // height: 100%; height: 100vh; // *
  }
  &.active {
    @include transform(translate(0%,0%));
  }
  .close {
    position: absolute;
    left: em(39px); top: em(39px);
    width: em(20px);
    cursor: pointer;
    @include mobile {
      width: em(25px);
    }
  }
  .menu-title {
    @extend b;
    text-align: center;
    padding: em(34px) 0;
    a { font-size: em(32px); }
    a:hover { text-decoration: none; }
    @include mobile {
      padding: em(39px) 0;
      a { font-size: em(32px); }
    }
  }
  .anchored-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: calc(100% - #{em(32px + 34px + 34px)});
    @include mobile {
      min-height: calc(100% - #{em(32px + 34px + 34px)});
    }
    nav {
      position: relative;
      padding: em(39px) em(39px) 0 em(39px);
      @include vendor(column-count, 4);
      @include vendor(column-gap, 1em);
      @include mobile {
        @include vendor(column-count, 1);
        padding: em(42px);;
        text-align: center;
      }
      .menu-section {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        h5 {
          margin-bottom: em(30px);
          @include mobile {
            font-size: em(18px);
            margin-bottom: 0;
            line-height: 2.6666;
          }
        }
        ul {
          padding-bottom: em(60px);
          li {
            font-size: em(25px);
            @extend b;
            line-height: 1.2;
            @include mobile {
              font-size: em(30px);
              line-height: 1.6;
            }
            a {
              position: relative;
              overflow: visible;
            }
            a[data-tooltip]:after {
              content: attr(data-tooltip);
              position: absolute;
              bottom: 0.5em;
              right: 0;
              transform: translateX(110%);
              text-decoration: none;
              font-size: 0.4em;
              font-family: $body-font-family;
              background-color: #ffffff;
              color: $body-color;
              padding: 0.3em;
              border-radius: 0.5em;
              opacity: 0;
              transition: 0.2s;
              pointer-events: none;
              white-space: nowrap;
              letter-spacing: em(1px);
            }
            a[data-tooltip]:hover:after {
              opacity: 1;
              z-index: 10;
            }
          }
        }
        .artist-thumbnail {
          position: absolute;
          left: em(39px);
          bottom: 100%;
          width: 12.5%;
          opacity: 0;
          transition: opacity 0.2s;
        }
      }
    }
  }
  footer {
    border-top: 1px solid #000000;
    .contact {
      padding-bottom: em(39px);
    }
  }
}

body > .content {
  $header-height: 39px + 39px + 15px;
  $header-height-mobile: 42px + 42px + 25px;
  padding-top: em($header-height + 161px);
  @include mobile {
    padding-top: em($header-height-mobile + 250px);
  }
  &.page-spacer {
    padding-top: 100%;
    padding-top: calc(100% - #{em(39px)});
    padding-top: 100vh;
    padding-top: calc(100vh - #{em(39px)});
    @include mobile {
      padding-top: em($header-height-mobile + 250px);
    }
  }
}

body > footer, .menu-tray footer {
  padding-top: em(39px);
  padding-left: calc(#{em(39px)} - 0.5em);
  padding-right: calc(#{em(39px)} - 0.5em);
  @include mobile {
    padding-top: em(42px);
    padding-left: em(42px);
    padding-right: em(42px);
  }
  .contact {
    padding-bottom: em(250px);
    .columns {
      width: 75%;
      .history {
        width: em(285px);
        width: (100% / 3);
        p {
          margin-top: 0;
          font-size: em(12px);
          line-height: 1.25;
        }
      }
      .address {
        width: em(200px);
        width: (100% / 3);
        p {
          font-size: em(12px);
          line-height: 1.25;
          b {
            font-size: em(12px);
          }
        }
        p:first-child { margin-top: 0; }
      }
      .social {
        width: (100% / 3);
        a {
          font-size: em(12px);
          @extend b;
          line-height: 1.25;
        }
      }
      > div {
        display: flex;
        justify-content: space-between;
        > * {
          @include inline-block;
          vertical-align: top;
          padding-left: 0.5em;
          padding-right: 0.5em;
        }
      }
    }
    .contact-form {
      width: 25%;
      padding-left: 0.5em;
      padding-right: 0.5em;
      form {
        width: 100%;
        @include inline-block;
        border-bottom: 1px solid;
        padding-bottom: em(15px);
        input {
          width: em(292px);
          width: calc(100% - #{em(27px)} - 1em);
          font-size: em(12px);
          @extend b;
          border: 0; outline: 0; padding: 0;
          background: transparent; color: inherit;
          @include placeholder {
            color: inherit;
          }
          &:focus {
            @include placeholder {
              color: transparent;
            }
          }
        }
        button {
          font-size: 1em;
          border: 0; padding: 0; outline: 0;
          cursor: pointer;
          background: transparent;
          img {
            width: em(25px);
          }
        }
      }
    }
    @include mobile {
      display: none;
    }
  }
  .mobile-contact {
    display: none;
    @include mobile {
      display: inline-block;
      .mobile-social {
        @include inline-block;
        width: em(312px);
        font-family: $bold-font-family;
        text-transform: uppercase;
        letter-spacing: normal;
        padding-right: em(42px);
        a {
          line-height: 2.2222;
        }
      }
      .mobile-social, .mobile-contact-form {
        vertical-align: top;
      }
      .mobile-contact-form {
        @include inline-block;
        form {
          @include inline-block;
          padding-bottom: em(15px);
          border-bottom: 1px solid;
          input {
            width: em(312px);
            font-size: em(18px);
            font-family: $bold-font-family;
            letter-spacing: normal;
            text-transform: uppercase;
            border: 0; outline: 0; padding: 0;
            background: transparent; color: inherit;
            @include placeholder {
              color: inherit;
            }
          }
          button {
            font-size: 1em;
            border: 0; padding: 0; outline: 0;
            cursor: pointer;
            background: transparent;
            img {
              width: em(25px);
            }
          }
        }
      }
      .mobile-history, .mobile-address {
        @include inline-block;
        width: em(312px);
        vertical-align: top;
        margin-top: em(200px);
        margin-bottom: em(200px);
        line-height: 1.1666;
      }
      .mobile-history {
        padding-right: em(42px);
        p {
          margin: 0;
        }
      }
      .mobile-address {
        h5 {
          font-size: em(18px);
          font-family: $bold-font-family;
          text-transform: uppercase;
          letter-spacing: normal;
        }
        p {
          margin: 0;
          padding-bottom: em(20px);
        }
      }
    }
  }
  .copyright {
    padding-bottom: em(39px);
    @include mobile {
      padding-bottom: em(42px);
    }
    p {
      font-size: em(12px);
      @include mobile {
        font-size: em(18px);
      }
    }
  }
}

body > footer {
  background-color: #ffffbe;
  @include vendor(animation, bgloop 20s infinite);
}

@keyframes bgloop {
  0% { background-color: #ffffbe; }
  25% { background-color: #ffeae0; }
  50% { background-color: #c8f0ff; }
  75% { background-color: #d7ffdc; }
  100% { background-color: #ffffbe; }
}

// Home page

.home, .speciality {
  a:hover {
    text-decoration: none;
  }
  @include mobile {
    margin-bottom: em(50px);
  }
  .homepage-pattern {
    .artist-name {
      margin-top: em(20px);
      @extend b;
      @include mobile {
        margin-top: em(10px);
      }
    }
    .artist-name, .artist-title {
      line-height: 1.1875;
      @include mobile {
        line-height: 1.2222;
      }
    }
  }
}

// Artist page

.artist, .group {
  .artist-page-pattern {
    a {
      &:hover { text-decoration: none; }
      .image-title {
        margin-top: em(20px);
        @include mobile {
          margin-top: em(10px);
        }
      }
    }
  }
}

.artist {
  margin-bottom: em(250px);
  @include mobile {
    margin-bottom: em(250px);
  }
  .artist-bio {
    width: em(621px);
    margin: 0 auto;
    @include mobile {
      width: em(666px);
    }
    p {
      font-size: em(24px);
      @extend b;
      line-height: 1.2083;
      margin-top: 0;
      @include mobile {
        font-size: em(24px);
        line-height: 1.2083;
      }
    }
    .artist-social {
      h2 {
        font-size: 1em;
        margin-top: em(40px);
        margin-bottom: em(40px);
        @include mobile {
          margin-top: em(60px);
          margin-bottom: em(10px);
        }
      }
      p {
        @extend b;
        line-height: 1.2083;
        margin-bottom: 0;
        @include mobile {
          line-height: 1.6;
        }
      }
    }
  }
}

// Project page

// .artist-project {
//   text-align: center;
//   width: em(956px);
//   padding-bottom: em(250px);
//   margin: 0 auto;
//   @include mobile {
//     width: em(668px);
//   }
//   h1 {
//     font-size: em(32px);
//   }
//   .project-images {
//     img, .video-wrap {
//       margin: em(40px) 0;
//       @include mobile {
//         margin: em(42px) 0;
//       }
//       &:first-child { margin-top: 0; }
//       &:last-child { margin-bottom: 0; }
//     }
//   }
//   .project-legend {
//     text-align: left;
//     //@extend b;
//     margin-top: em(20px);
//     @include mobile {
//       margin-top: em(10px);
//     }
//   }
// }

.artist-project {
  margin-bottom: em(200px);
  .project-images {
    .project-block {
      @include inline-block;
      width: 50%;
      vertical-align: top;
      padding-left: em(20px);
      padding-right: em(20px);
      margin-bottom: em(40px);
      @include mobile {
        padding-left: em(21px);
        padding-right: em(21px);
      }
      &:last-child, &:nth-last-child(2) { margin-bottom: 0; }
    }
    .project-image {
      
    }
    .project-video {
      width: 100%;
    }
  }
  .project-legend {
    padding-left: em(40px);
    margin-top: em(20px);
    @include mobile {
      padding-left: em(42px);
      margin-top: em(10px);
    }
    .title {
      @extend b;
    }
    p {
      margin: 0;
    }
  }
  .next-project {
    margin-top: em(400px);
    text-align: center;
    @extend b;
    a { font-size: em(32px); }
    a:hover { text-decoration: none; }
  }
}


.error-page {
  text-align: center;
  margin-bottom: em(100px);
}
